

$('.toggle-password').on('click', function () {

  $(this).toggleClass("fa-eye fa-eye-slash");

  var inp = $('.form-group #id_create-password1, .form-group #id_password, #id_new_password1');
  if (inp.attr('type') == 'password') {
      inp.attr('type','text');
  } else {
        inp.attr('type','password');
    }
});

$('.toggle-password2').on('click', function () {

  $(this).toggleClass("fa-eye fa-eye-slash");

  var inp = $('.form-group #id_create-password2, #id_new_password2');
  if (inp.attr('type') == 'password') {
      inp.attr('type','text');
  } else {
        inp.attr('type','password');
    }
});


var zxcvbn = require('zxcvbn');

$.behaviors('.authFormSignup', setUpSignUpForm);
$.behaviors('.authFormThirdPartySignUp', setUpThirdPartySignUpForm);

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

function setUpSignUpForm(signUpForm) {
  var signUpForm = $(signUpForm)
  var passwordStrength = signUpForm.find('.passwordStrength');
  signUpForm.find('input').on('change keyup paste', checkFormValidity);
  passwordStrength.on('password-score-change', checkFormValidity);

  function checkFormValidity() {
    var disabled = true;
    var username = signUpForm.find("#id_create-username").val();
    var email = signUpForm.find("#id_create-email").val();
    var password1 = signUpForm.find("#id_create-password1").val()
    var password2 = signUpForm.find("#id_create-password2").val()
    var isValidEmail = validateEmail(email);
    let termsCheckbox = document.querySelector("#terms_checkbox").checked

    if (email && !isValidEmail) {
      signUpForm.find(".authFormSignup-invalidEmail").removeClass('hidden');
    } else {
      signUpForm.find(".authFormSignup-invalidEmail").addClass('hidden');
    }
    if (username && isValidEmail && password1 && password2 && passwordStrength.data('score') >= min_password_score && termsCheckbox) {
      disabled = false;
    }

    if (disabled) {
      signUpForm.find(".button.cta.formSubmit").addClass("disabled");
    } else {
      signUpForm.find(".button.cta.formSubmit").removeClass("disabled");
    }
  }
}

function setUpThirdPartySignUpForm(signUpForm) {
  var signUpForm = $(signUpForm);
  signUpForm.find('input').on('change keyup paste', checkFormValidity);
  checkFormValidity();

  function checkFormValidity() {
    var disabled = true;
    var email = signUpForm.find("#id_email").val();
    var isValidEmail = validateEmail(email);
    let termsCheckbox = document.querySelector("#terms_checkbox").checked

    if (email && !isValidEmail) {
      signUpForm.find(".authFormSignup-invalidEmail").removeClass('hidden');
    } else {
      signUpForm.find(".authFormSignup-invalidEmail").addClass('hidden');
    }

    if (isValidEmail && termsCheckbox) {
      disabled = false;
    }

    if (disabled) {
      signUpForm.find(".button.cta.formSubmit").addClass("disabled");
    } else {
      signUpForm.find(".button.cta.formSubmit").removeClass("disabled");
    }
  }
}
