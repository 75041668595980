var $ = require('jquery');

$.behaviors('.paymentTotal', function(container) {
  container = $(container);
  var summary = container.find('.paymentTotal-summary');
  var removeLanguage = container.find('.removeLanguage');
  var removeLanguageFields = container.find('.removeLanguage-field');
  var languageSelectField = container.find('[data-addon-name="languages"]')


  function updateSummary() {
    var subtotal = calcSubtotal();
    var total = subtotal - calcDiscount();
    updateSummaryRow('.paymentTotal-subtotal', subtotal);
    updateSummaryRow('.paymentTotal-total', total);
  }

  function calcSubtotal() {
    var amount = summary.data('baseCost');
    container.find('.paymentTotal-component').each(function() {
      amount += componentCost($(this));
    });
    return amount;
  }

  function calcDiscount() {
    var discount = 0;
    container.find('.paymentTotal-discount').each(function() {
      discount += $(this).data('amount');
    });
    return discount;
  }

  function updateSummaryRow(selector, amount) {
    var tr = container.find(selector);
    if(tr.length > 0) {
      var cellText = tr.data('template').replace('{amount}', amount);
      tr.find('td').text(cellText);
    }
  }

  function updateRemoveLanguageFields() {
    var languageCount = removeLanguage.data('languageCount');
    var selectedLanguageCount = parseInt(languageSelectField.find('select').val()) + languageSelectField.data('baseUnits');
    if (selectedLanguageCount < languageCount) {
      removeLanguage.show();
      for(var i=0; i < languageCount; i++) {
        console.log(removeLanguageFields[i])
        if (i < languageCount - selectedLanguageCount) {
          $(removeLanguageFields[i]).parent().show();
        } else {
          $(removeLanguageFields[i]).parent().hide();
        }
      }
    } else {
      removeLanguage.hide();
      removeLanguageFields.each(function() {
        $(this).parent().hide();
      })
    }
  }

  function componentCost(component) {
    return parseInt(component.find('select').val()) * component.data('scale');
  }

  container.on('change', updateSummary);
  container.on('change', updateRemoveLanguageFields);
  updateSummary();
  updateRemoveLanguageFields();
});
