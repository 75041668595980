/* Amara, universalsubtitles.org
 *
 * Copyright (C) 2017 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

var $ = require('jquery')
var Chartist = require('chartist')

// data is a dictionary of the format { labels: [ "label1", "label2", ], series: [[ value1, value2, ]]}
// note that <series> is a two-dimensional array
// `available_width` is the width that the chart can use
function create_dashboard_stats_chart(data, available_width, chart_height, chart_id) {
  var BAR_WIDTH = 40
  var MIN_CHART_WIDTH = 220 // this is roughly the width of the header "Top languages (all time)"

  var chart_width = 0
  var bars = 0
  var chart_data = {}
  if (data["labels"].length * BAR_WIDTH > available_width) {
    bars = Math.floor(available_width / BAR_WIDTH)
    chart_data = {
      labels: data["labels"].slice(0, bars),
      series: [data["series"][0].slice(0, bars)]
    }
  } else {
    bars = data["labels"].length
    chart_data = data
  }
  chart_width = bars * BAR_WIDTH
  if (chart_width < MIN_CHART_WIDTH) {
    chart_width = MIN_CHART_WIDTH
  }

  var chart_options = {
    width: chart_width,
    height: chart_height,
    axisX : { showGrid: false },
    axisY : { onlyInteger: true,
              scaleMinSpace: 30, 
              // this is based on the fact that the dashboard stats numbers is around
              // 318px tall, so we want roughly 10 steps in the chart. Need to update this
              // if the style of the dashboard stats numbers is changed
              showGrid: true },
    plugins: [ 
      Chartist.plugins.tooltip({ 
        class: 'chartist-tooltip',
        appendToBody: false,
      })
    ]
  }
  var chart = new Chartist.Bar(chart_id, chart_data, chart_options)
  chart.update()
}

$.behaviors('#subtitle-chart-30-days', function(container) {
    var container = $(container)

    /**** begin sample data ****/
    // var x = [
    //     "en", "es", "pt-br", "it", 
    //     // "fr", "zh-cn", "ru", "ko", // intentionally left this row out
    //     "he", "zh", "ja", "ar",
    //     "el", "ia", "rw", "ky", "qv", "hz", "ro", "jv", "kl", "cu", "ny", "fj",
    //     "nb", "se", "pl", "fa", "os", "gv", "ml", "nv", "ga", "iu", "lo", "lb",
    //   ]
    // var y = 
    //     [7790, 6476, 4494, 4216, 
    //     // 4104, 3860, 3674, 3658, // intentionally left this row out
    //     3563, 3551, 3368, 3344,
    //     3109, 2589, 2478, 2389, 2356, 2168, 1978, 1901, 1876, 1784, 1567, 1324,
    //     1231, 1199, 1158, 1025, 843, 768, 754, 734, 431, 231, 223, 209,
    //     ]

    // var dataLang = {
    //   labels: x,
    //   series: [y]
    // }
    /**** end sample data ****/

    var dataLang = container.data('chart-data')

    var chartContainer = $('#dashboard-stats-section')
    var statsNumbersContainer = chartContainer.find('.dashboard-stats-numbers')
    var remainingWidth = chartContainer.width() - statsNumbersContainer.outerWidth() - 25

    create_dashboard_stats_chart(dataLang, remainingWidth, statsNumbersContainer.height(), '#subtitle-chart-30-days')
});

$.behaviors('#subtitle-chart-all-time', function(container) {
    var container = $(container)

    /**** begin sample data ****/
    // var x = [
    //     "en", "es", "pt-br", "it", 
    //     "fr", "zh-cn", "ru", "ko", 
    //     "he", "zh", "ja", "ar",
    //     "el", "ia", "rw", "ky", "qv", "hz", "ro", "jv", "kl", "cu", "ny", "fj",
    //     "nb", "se", "pl", "fa", "os", "gv", "ml", "nv", "ga", "iu", "lo", "lb",
    //   ]
    // var y = 
    //     [7790, 6476, 4494, 4216, 
    //     4104, 3860, 3674, 3658,
    //     3563, 3551, 3368, 3344,
    //     3109, 2589, 2478, 2389, 2356, 2168, 1978, 1901, 1876, 1784, 1567, 1324,
    //     1231, 1199, 1158, 1025, 843, 768, 754, 734, 431, 231, 223, 209,
    //     ]

    // var dataLang = {
    //   labels: x,
    //   series: [y]
    // }
    /**** end sample data ****/

    var dataLang = container.data('chart-data')

    var chartContainer = $('#dashboard-stats-section')
    var statsNumbersContainer = chartContainer.find('.dashboard-stats-numbers')
    var remainingWidth = chartContainer.width() - 25

    create_dashboard_stats_chart(dataLang, remainingWidth, statsNumbersContainer.height(), '#subtitle-chart-all-time')
});

