// require jquery and all plugins first, this way if other modules depend on jquery they will get the plugins too
window.jQuery = window.$ = require('jquery');
require('bootstrap-sass');
require('jquery-form')(window, $);
require('dropzone/dist/dropzone-min.js');
require('select2')(window, $);
require('../shared/third-party/behaviors');
require('../shared/announcements');
require('../shared/messages');

// require all the other modules
// Third party libraries
require('chartist');
require('chartist-plugin-tooltip');
// Amara modules
require('../shared/ajax');
require('../shared/formSubmit');
require('../shared/selectRange');
require('../shared/copyText');
require('./proxyField');
require('./dependentSelect');
require('../shared/languageSwitcher');
require('../shared/select/main');
require('./selectList');
require('./videoPage');
require('../shared/videoSubtitles');
require('../shared/fileUpload');
require('../shared/imageInput');
require('../shared/compoundField');
require('./clamp');
require('../shared/tabs');
require('../shared/formValidation');
require('../shared/teamRoleSelect');
require('../shared/multipleAutoCompleteSelect');
require('../shared/videoCreatePage');
require('../shared/multiField');
require('../shared/formBacktotop');
require('../shared/formSaveChanges');
require('../shared/teamSettings');
require('../shared/dynamicHelpText');
require('../shared/dropdown');
require('../shared/filterBox');
require('../shared/listView');
require('../shared/checkAll');
require('../shared/workflowSettings');
require('../shared/contentHeader');
require('../shared/expandButton');
require('../shared/actionBar');
require('../shared/menuToggle');
require('../shared/teamStats');
require('../shared/accordion');
require('../shared/timezones');
require('../shared/manageRequestsView');
require('../shared/dynamicFormset');
require('../shared/presetOrCustom');
require('../shared/downloadSubtitles');
require('../shared/tableSelect');
require('../shared/toggleOnInputValue');
require('../shared/scrollToElement');
require('../shared/selectionGroup');
require('../shared/payments');
require('../shared/multiStepPane');
require('../shared/radioItemSubfield');
require('../shared/tooltip');
require('../shared/orderPage');
require('./collab');
require('../auth/signup');
require('../auth/passwordStrength');
require('../shared/workspace');
require('../shared/expandableFieldset');
require('../shared/requireSelection');
require('../shared/prorequests');
require('../shared/dependentField');
require('../shared/autoFilter');
require('./uploader');
require('../shared/cookieConsent');
require('./asrCalloutHider')
