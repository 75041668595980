// Copyright 2019 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');
var dialogs = require('./dialogs');
var select = require('./select/main');

$.behaviors('.dynamicFormset', function(container) {
    container = $(container);

    var addDialog = $(container.data('addDialog'));
    var addButton = $(container.data('addButton'));
    var labelInputs = $('.dynamicFormset-labelInput :input', container);
    var prefix = container.data('prefix')
    var addDialogInput = findAddInput();
    var addFormButton = $('button.cta', addDialog);
    var totalFormsInput = $(':input[name=' + prefix + '-TOTAL_FORMS]');
    var emptyFormHTML = window[container.data('emptyFormVar')];

    addFormButton.on('click', function(evt) {
        evt.preventDefault();
        var selectedValue = addDialogInput.val();
        if(!selectedValue) {
            return;
        }
        var selectedLabel = calcFormsetLabel();

        // Create a new form and add it to the accordian
        var form = makeNewForm(addDialogInput.val());
        container.accordion('addSection', selectedLabel, form);

        incFormCount();
        cleanupDialogAfterAddingForm();
        dialogs.closeCurrentModal();
        return false;
    });

    addButton.on('click', function() {
        var form = makeNewForm();
        container.accordion('addSection', "New", form);
        incFormCount();
        return false;
    });

    labelInputs.on('change input', function(evt) {
        var input = $(this);
        var title = input.val();
        if(!title) {
            title = input.closest('.dynamicFormset-labelInput').data('emptyLabel');
        }
        container.accordion('updateTitle', input.closest('.accordion-section'), title);
    });

    function currentFormCount() {
        return parseInt(totalFormsInput.val());
    }

    function incFormCount() {
        totalFormsInput.val(currentFormCount() + 1);
    }

    function findAddInput() {
        // Assume there is exactly 1 field in the form.  We will use the value of that input to create the new formset
        return $('.form-group :input', addDialog);
    }

    function calcFormsetLabel() {
        if(addDialogInput.is('select')) {
            return $('option:selected', addDialogInput).text();
        } else {
            return addDialogInput.val();
        }
    }

    function cleanupDialogAfterAddingForm() {
        if(addDialogInput.is('select')) {
            $('option:selected', addDialogInput).remove();
            select.reloadSelect(addDialogInput);
        }
    }

    function makeNewForm(hiddenInputVal) {
        var form = $('<div>').append($(emptyFormHTML.replace(/__prefix__/g, currentFormCount())));
        if(hiddenInputVal) {
            $('.dynamicFormset-hiddenInput :input' , form).val(hiddenInputVal);
        }
        form.updateBehaviors();
        return form;
    }
});

$.behaviors('.dynamicFormset-delete', function(button) {
    button = $(button);
    button.on('click', function(evt) {
        var section = button.closest('.accordion-section');
        section.hide();
        section.append($('<input>', {
            type: 'hidden',
            name: button.data('inputName'),
            value: 1,
        }));
        button.closest('form').trigger('customchange');
        evt.preventDefault();
    });
});
