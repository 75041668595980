// Copyright 2020 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

$.behaviors('.requireSelection', function(button) {
  button = $(button);
  var form = button.closest('form');

  function updateButton() {
    var disabled = !hasRequiredSelection();
    button.prop('disabled', disabled);
    button.toggleClass('disabled', disabled);
  }

  function hasRequiredSelection() {
    var inputNames = button.data('required').split(' ');
    for(var i=0; i < inputNames.length; i++) {
      if(!fieldSelected(inputNames[i])) {
        return false;
      }
    }
    return true;
  }

  function fieldSelected(name) {
    var selected = false;

    function checkElement(elt) {
      if(elt.type == 'checkbox' || elt.type == 'radio') {
        return elt.checked;
      } else {
        return elt.value;
      }
    }

    form.find(':input[name=' + name + ']').each(function() {
      if(checkElement(this)) {
        selected = true;
        return false;
      }
    });

    return selected;
  }

  updateButton();
  form.on('change input', updateButton);
});
