// Copyright 2019 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

var presetDigitRE = /^preset-(\d+)$/;

$.fn.extend({
    presetOrCustom: function() {
        if(!this.data('presetOrCustom')) {
            this.data('presetOrCustom', new PresetOrCustom(this));
        }
        return this.data('presetOrCustom');
    }
});

function PresetOrCustom(elt) {
    this.elt = elt;
    this.radioButtons = $('input[type=radio]', elt);
    this.customTextInput = $('.presetOrCustom-textInput', elt);
    this.customRadioButton = $('.presetOrCustom-radio', elt);

    this.setupChangeEvent();
    this.handleCustomInput();
}

PresetOrCustom.prototype = {
    value: function() {
        var currentRadio = this.radioButtons.filter(':checked');
        if(currentRadio.val() == 'custom') {
            return this.customTextInput.val();
        } else {
            return currentRadio.closest('.presetOrCustom-item').find('.presetOrCustom-text').text();
        }
    },
    setupChangeEvent: function() {
        this.radioButtons.on('change', this.sendChangeEvent.bind(this));
        this.customTextInput.on('input change', this.sendChangeEvent.bind(this));
    },
    sendChangeEvent: function() {
        this.elt.trigger('presetOrCustomChange', this);
    },
    handleCustomInput: function() {
        var self = this;
        this.customRadioButton.on('change input', function() {
            if(self.customRadioButton.is(':checked')) {
                self.customTextInput.focus();
            }
        });

        this.customTextInput.on('focus', function() {
            self.customRadioButton.prop('checked', true);
        });
    }
}

$.behaviors('.presetOrCustom', function(container) {
    $(container).presetOrCustom();
});
