// Copyright 2020 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

$.behaviors('.hideOnInputValue', hideOnInputValue);
$.behaviors('.showOnInputValue', showOnInputValue);

function hideOnInputValue(container) {
    container = $(container);
    var source_input = $('#' + container.data('inputId'))

    function checkInput(input) {
        if(shouldHide(container, input)) {
            container.hide();
        } else {
            container.show();
        }
    }

    checkInput(source_input);
    source_input.on('change', function(evt) {
        checkInput(source_input);
    });
}

function shouldHide(container, input) {
    if(container.data('hideIf') && input.val() == container.data('hideIf')) {
        return true;
    }
    return false;
}

function showOnInputValue(container) {
    container = $(container);
    var source_input = $('#' + container.data('inputId'))

    function checkInput(input) {
        if(shouldShow(container, input)) {
            container.show();
        } else {
            container.hide();
        }
    }

    checkInput(source_input);
    source_input.on('change', function(evt) {
        checkInput(source_input);
    });
}

function shouldShow(container, input) {
    if(container.data('showIf') && input.val() == container.data('showIf')) {
        return true;
    }
    return false;
}