// Copyright 2016 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');
var ajax = require('../shared/ajax');

$.behaviors('.collabVideoAssignments', collabVideoAssignments);
$.behaviors('#my-assignments-dropdown', myAssignmentsDropdown);
$.behaviors('.manageRequestsCreateRequest', manageRequestsCreateRequestForm);

function collabVideoAssignments(container) {
    var filter = $('.collabVideoAssignments-filter', container);

    filter.on('change', function(evt) {
        if(filter.val() == 'filtered') {
            updateSections(true);
        } else {
            updateSections(false);
        }
    });
    updateSections(true);

    function updateSections(filteredSetOnly) {
        $('.collabVideoAssignments-section', container).each(function (i, section) {
            var items = $('.collabVideoAssignments-item', section);
            var visibleCount = 0;
            if(filteredSetOnly) {
                var visibleItems = items.filter('.inFilteredSet');
                var hiddenItems = items.not('.inFilteredSet');
            } else {
                var visibleItems = items;
                var hiddenItems = $([]);
            }
            visibleItems.removeClass('hide');
            hiddenItems.addClass('hide');
            $('.count', section).text('(' + visibleItems.length + ')');
            if(visibleItems.length > 0) {
                $(section).removeClass('hide');
            } else {
                $(section).addClass('hide');
            }
        });
    }
}

function myAssignmentsDropdown(dropdown) {
    $(dropdown).on('link-activate', function(evt, action) {
        if(action == 'decline') {
            var card = evt.openerButton.closest('.assignmentCard');
            if(card.length && card.data('declineUrl')) {
                ajax.update(card.data('declineUrl'), {
                    keepState: true
                });
            }
        }
    });
}

function manageRequestsCreateRequestForm(form) {
    var video = $('select[name=video]', form);
    var videoLanguageField = $('select[name=video_language]', form);
    var videoLangaugeContainer = $('.manageRequestsCreateRequest-videoLanguage', form);

    if(videoLangaugeContainer.hasClass('hideInitially')) {
        disableVideoLanguage();
    }
    video.on('select2:select', function() {
        var selection = video.select2('data');
        if(selection && selection[0] && selection[0].primaryAudioLanguage) {
            disableVideoLanguage();
        } else {
            enableVideoLanguage();
        }
    });

    function disableVideoLanguage() {
        videoLanguageField.attr('disabled', true)
        videoLangaugeContainer.hide();
    }
    function enableVideoLanguage() {
        videoLanguageField.attr('disabled', false);
        videoLangaugeContainer.show();
    }
}
