// Copyright 2020 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

$.behaviors('.dependentField', dependentField);

function dependentField(container) {
  container = $(container);
  var checkbox = $('#' + container.data('field'));

  container.on('change', function(evt) {
    if(inputIsSet($(evt.target))) {
      checkbox.prop('checked', true);
    }
  });
  checkbox.on('change', function(evt) {
    if(container.hasClass('disableWhenUnchecked')) {
      updateDisabledInputs();
    } else {
      clearInputsIfUnchecked();
    }
  });

  function clearInputsIfUnchecked() {
    if(!checkbox.prop('checked')) {
      container.find(':input').each(function() {
        clearInput($(this));
      });
    }
  }

  function updateDisabledInputs() {
    if(container.hasClass('disableWhenUnchecked')) {
      container.find(':input').prop('disabled', !checkbox.prop('checked'));
    }
  }

  updateDisabledInputs();
}

function inputIsSet(input) {
  if(input.is(':checkbox')) {
    return input.prop('checked');
  } else {
    return input.val() != '';
  }
}

function clearInput(input) {
  if(input.is(':checkbox')) {
    input.prop('checked', false);
  } else {
    input.val('').change();
  }
}
