// Copyright 2019 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

$.behaviors('.tableSelect', function(container) {
    $(container).on('click', function(evt) {
        if (evt.target.tagName.toLowerCase() !== 'a') {
            $(evt.target).closest('tr', container).find('input[type=checkbox').each(function() {
                var checkbox = $(this);
                checkbox.prop('checked', !checkbox.prop('checked')).change();
            });
            evt.stopPropagation();
            evt.preventDefault();    
        }
    });
});

