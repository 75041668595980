/**
 * Handle payments forms
 *
 * Note that this requires the "payments/head-script.html" template to be loaded in head-extra
 */

var $ = require('jquery');
var cookies = require('browser-cookies');
var multiStepPane = require('../multiStepPane');
var dialogs = require('../dialogs');
var stripe = require('./stripe');

var style = {
  base: {
    color: "#222626",
    fontFamily: '"Open Sans", sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#787a7b"
    },
    ":disabled": {
      color: "#787a7b",
    }
  },
  invalid: {
    color: "#f3254d",
    iconColor: "#f3254d",
    ":disabled": {
      color: "#787a7b",
      iconColor: "#787a7b"
    }
  }
};

$.behaviors('.paymentForm', function(form) {
  form = $(form);
  var elements = stripe.elements();
  var error = null;
  var insideDialog = form.hasClass('insideDialog');
  var setMessage = form.hasClass('setMessage');
  form.data('elements', elements);
  form.on('submit', function(evt) {
    evt.preventDefault();
    resetError();
    // disable submit button to prevent extra clicks from user
    // while call to stripe API is made. Add spinner to let user
    // know processing is ongoing
    submit_btn = $('.paymentForm .formSubmit');
    submit_btn.attr('disabled', 'disabled');
    submit_btn.addClass('disabled');
    addSpinner(submit_btn);
    if(existingPaymentRadio().is(':checked')) {
      setTimeout(function() {
        // Put this in setTimeout so that it's guarenteed to happen after the formSubmit handler
        form.trigger('ajax-submit-success');
        form.trigger('ajax-submit-complete');
        
        //re-activate submit button which was disabled earlier given we 
        //are not making a call to submitNewPayment
        submit_btn.removeAttr('disabled');
        submit_btn.removeClass('disabled');
      }, 0);
      gotoNextStep();
    } else {
      submitNewPayment();
    }
  });

  function submitNewPayment() {
    stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement('card'),
      billing_details: calcBillingDetails(),
    }).then(function(stripeResult) {
      if (stripeResult.error) {
        handleError(stripeResult.error.message);
      } else {
        // Otherwise send paymentMethod.id to your server
        var url = '/api/payments/set-payment-method/';
        if(setMessage) {
          url += '?set-message=1';
        }
        $.ajax({
          url: url,
          headers: { "X-CSRFToken": cookies.get('csrftoken')},
          method: 'POST',
          data: {
            payment_method: stripeResult.paymentMethod.id,
            customer_name: stripeResult.paymentMethod.billing_details.name
          },
        }).then(function(result) {
          if(result.error) {
            handleError(result.error);
          } else {
            submit_btn = $('.paymentForm .formSubmit');
            //add delay to re-enabling button so it is not available
            //for clicking immediately before next step is shown.
            setTimeout(function(){
              submit_btn.removeAttr('disabled');
              submit_btn.removeClass('disabled');
            }, 300);
            removeSpinner(submit_btn);
            form.trigger('ajax-submit-success');
            form.trigger('ajax-submit-complete');
            updatePaymentElements(stripeResult);
            gotoNextStep();
          }
        }).catch(function(error) {
          console.trace(error);
          handleError();
        });
      }
    }).catch(function(error) {
      console.trace(error);
      handleError();
    });
  }

  function calcBillingDetails() {
    var name = form.find('[name=payment-name]').val();
    if(name) {
      return {name: name};
    } else {
      return {};
    }
  }

  function handleError(errorText) {
    form.trigger('ajax-submit-success');
    form.trigger('ajax-submit-complete');
    if(!errorText) {
      errorText = gettext('Error submitting payment');
    }
    setError(errorText);
    //re-enable submit button
    submit_btn = $('.paymentForm .formSubmit');
    submit_btn.removeAttr('disabled');
    submit_btn.removeClass('disabled');
    removeSpinner(submit_btn);
  }

  function setError(errorText) {
    if(error === null) {
      error = $('<div class="alert alert-error" role="alert">')
      if(insideDialog) {
        error.prependTo(form.find('.modal-content'));
      } else {
        error.prependTo(form);
      }
    }
    error.text(errorText);
  }

  function resetError() {
    if(error) {
      error.remove();
      error = null;
    }
  }

  function existingPaymentRadio() {
    return form.find('.radioSelect-input[name=payment-payment_radio][value=existing]');
  }

  function updatePaymentElements(result) {
    var card = result.paymentMethod.card;
    var paymentDisplay = card.brand.toUpperCase() + ' XXXX XXXX XXXX ' + card.last4 + ' ' + card.exp_month.toString().padStart(2, '0') + '/' + card.exp_year;

    // Replace the text for the current payment elements
    var cardInfoElt = form.data('cardInfoElt');
    if(cardInfoElt) {
      $(cardInfoElt).empty().text(paymentDisplay);
    }
    $('.paymentForm-currentPayentLabel', form).text(paymentDisplay);
    // Reset the payment form by:
    //   - Clearing out the change payment element
    //   - Selecting the "Use existing payment" radio
    elements.getElement('card').clear();
    existingPaymentRadio().prop('checked', true).change();
  }

  function gotoNextStep() {
    var next = form.data('next');
    if(typeof next === 'function') {
      next();
    } else if(next == 'multiStepPane-next') {
      multiStepPane.gotoNext();
    } else if(next == 'reload') {
      window.location.reload(false);
    }

    if (form.data('auto-scroll')) {
      $(window).scrollTop(0);
    }
  }

  function addSpinner(submitButton) {
    submitButton.find('.spinner').remove();
    submitButton.prepend($('<span class="spinner"><span class="fas fa-circle-notch fa-spin"></span> </span>'));
  }

  function removeSpinner(submitButton) {
    submitButton.find('.spinner').remove();
  }

  form.on('set-error', function(evt, error) {
    setError(error);
  });



});

$.behaviors('.paymentForm-card', function(elt) {
  elt = $(elt);
  var form = elt.closest('.paymentForm');
  var elements = form.data('elements');
  var formGroup = elt.closest('.form-group');
  var errorContainer = formGroup.find('.paymentForm-cardErrors');

  var card = elements.create("card", { style: style });
  card.mount(elt[0]);
  card.addEventListener('change', function(event) {
    if (event.error) {
      errorContainer.text(event.error.message);
      formGroup.addClass('has-error');
    } else {
      errorContainer.text('');
      formGroup.removeClass('has-error');
    }
  });

  formGroup.on('set-inputs-disabled', function(evt, disabled) {
    card.update({disabled: disabled});
    errorContainer.toggleClass('disabled', disabled);
  });
});
