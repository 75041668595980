// Copyright 2020 Participatory Culture Foundation, All Rights Reserved
"use strict"
// var $ = require('jquery');
var _ = require('underscore');
var ajax = require('./ajax');
var querystring = require('./querystring');

$.behaviors('.orderPage-videoSearch', function(container) {
  var searchBar = $('.contentHeader-searchBar');
  searchBar.on('activate', function(evt, value) {
    ajax.update('?' + querystring.format({
      dialog: 'add-videos',
      q: value,
    }), {"keepState": true});
    return false;
  });
});

$.behaviors('.orderPage-form', function(container) {
  container = $(container);

  function getSubtitlesData() {
    let found_data = container.find('.orderPage-options').data('subtitles');
    if ($.isEmptyObject(found_data)){
      //no
    } else{
      return found_data
    }
  }

  function updateVideoDetailsSection(selection) {
    container.find('.orderPage-videoDetails').toggleClass('hidden', selection.languages.length === 0);
  }

  function updateBottomSection(selection) {
    container.find('.orderPage-bottom').toggleClass('hidden', selection.languages.length === 0);
    updateDetailsPrices(selection);
    updateTurnaroundDays(selection);
    updateTotalCost(selection);
  }

  function updateDetailsPrices(selection) {
    // no need to update the details prices for ASR
    if (!$('.orderPage-videoDetails').length) {
      return;
    }

    let subtitlesData = getSubtitlesData();
    function updatePrice(selector, priceTable, labelTemplate) {
      let rate = calcDetailsRate(selection, priceTable)
      let label = container.find(selector).find('label');
      // Delete previously added text
      label.find('.labelText').remove();
      // Append the new label text
      label.append($('<span class="labelText">').text(labelTemplate.replace('{amount}', formatPrice(rate))));
    }
    if ($.isEmptyObject(subtitlesData)){
      // skip update, no videos selected yet
    } else {
      updatePrice('.orderPage-difficultAudioField', subtitlesData.difficultAudio, gettext('Difficult Audio ({amount} per minute)'));
      updatePrice('.orderPage-technicalLanguageField', subtitlesData.technicalContent, gettext('Technical Language ({amount} per minute)'));
    }
  }

  function calcDetailsRate(selection, priceTable){
    let rate = 0;
    _.each(selection.languages, function(value) {
      rate += priceTable[value.source][value.target+"-Rate"];
    });
    return rate;
  }

  function calcDetailsPrice(selection, priceTable) {
    var price = 0;
    _.each(selection.languages, function(value) {
      price += priceTable[value.source][value.target];
    });
    return price;
  }

  function convert_duration(seconds) {
    let mins = Math.floor(seconds/60);
    let secs = seconds % 60;
    return mins.toString().padStart(2,'0') + ":" + secs.toString().padStart(2,'0');
  }

  function updateTurnaroundDays(selection) {
    // no need to update turnaround days for ASR
    if (!$('#turnaround-time').length) {
      return;
    }

    var turnaroundDaysMap = getSubtitlesData()['turnaroundDays'];
    // Number of days needed for captions for each video language
    var captionsDays = {};
    // Max number of days needed for a translation for each video language
    var translationDays = {};
    // Initialize caption/translation days
    _.each(selection.videoLanguages, function(lc) {
        captionsDays[lc] = 0;
        translationDays[lc] = 0;
    });
    // Calculate caption/translation days
    _.each(selection.languages, function(value) {
      var days = turnaroundDaysMap[value.source][value.target];
      if(value.source === value.target) {
        captionsDays[value.source] = Math.max(captionsDays[value.source], days);
      } else {
        translationDays[value.source] = Math.max(translationDays[value.source], days);
      }
    });
    var daysByLanguage = _.map(selection.videoLanguages, function(lc) {
      return captionsDays[lc] + translationDays[lc];
    });
    var days
    if(daysByLanguage.length > 0) {
      days = Math.max.apply(Math, daysByLanguage);
    } else {
      days = 3;
    }
    var template = ngettext('%(count)s business day', '%(count)s business days', days);
    $('#turnaround-time').text(interpolate(template, {count: days}, true));
  }

  function updateTotalCost(selection) {
    var subtitlesData = getSubtitlesData();
    var is_asr = false;
    // update order free minutes used - ASR
    var cost_covered = 0;
    if(subtitlesData!=undefined && !$('#turnaround-time').length){
      is_asr = true;
      cost_covered = subtitlesData.free_minutes.cost_covered;
      let order_duration_covered = subtitlesData.free_minutes.order_duration_covered;
      let free_min_covered = convert_duration(order_duration_covered);
      let remaining_min = subtitlesData.free_minutes.remaining_minutes;
      if(order_duration_covered>0){
        $('#free_min_calc').html(
          '<span>This order will use <strong>'+ free_min_covered +' free </strong> caption minutes. </br> You have\
          <strong>'+ remaining_min +' free minutes</strong> remaining.</span>'
        ).show();
      }else{
        $('#free_min_calc').hide();
      }
    };

    var total = 0;
    var languageTotals = {};
    _.each(selection.languages, function(value) {
      var price = subtitlesData.prices[value.source][value.target];
      total += price;
      if(languageTotals[value.source] === undefined) {
        languageTotals[value.source] = price;
      } else {
        languageTotals[value.source] += price;
      }
    });
    if(selection.technicalLanguage) {
      total += calcDetailsPrice(selection, subtitlesData.technicalContent);
    }
    if(selection.difficultAudio) {
      total += calcDetailsPrice(selection, subtitlesData.difficultAudio);
    }
    // ASR
    if(is_asr){
      let amt_to_pay = total - cost_covered;
      $('#total-less-free-min').html('\
        <strong>You pay:</strong> '+ formatPrice(amt_to_pay)).show();
    }
    $('#total-cost').text(formatPrice(total));
    $('.orderPage-subtotal').addClass('hidden');
    _.each(languageTotals, function(subTotal, languageCode) {
      $('#subtotal_' + languageCode).removeClass('hidden').find('.amount').text(formatPrice(subTotal));
    });
  }

  container.on('change', function() {
    var selection = calcFormSelection();
    updateVideoDetailsSection(selection);
    updateBottomSection(selection);
  }).change();
})

function formatPrice(price) {
  return '$' + (price / 100.0).toFixed(2);
}

function updateTotalAndTurnaroundTime() {
  $('.orderPage-options').change();
}

function calcFormSelection() {
  var selection = {
    languages: calcLanguageSelection(),
    difficultAudio: $('input[name="subtitles-difficult_audio"]').prop('checked'),
    technicalLanguage: $('input[name="subtitles-technical_language"]').prop('checked')
  };
  selection.videoLanguages = _.unique(_.map(selection.languages, function(value) { return value.source; }));
  return selection;
}

function calcLanguageSelection() {
  return $('.orderPage-subtitlesFieldsets :checkbox:checked').map(function() {
    var checkbox = $(this);
    var match = checkbox.attr('name').match(/^subtitles-subtitles_(.*)_(.*)$/)
    if(match) {
      return {source: match[1], target: match[2]};
    }
  }).get();
}

$.behaviors('.orderPage-subtitleFieldset', function(fieldset) {
  fieldset = $(fieldset);
  var captionCheckbox = $('.orderPage-captionsField input[type=checkbox]', fieldset);
  var translationCheckboxes = $('input[type=checkbox]', fieldset).not(captionCheckbox);

  fieldset.on('change', function(evt) {
    var input = $(evt.target);
    if(input.closest('.orderPage-captionsField').length > 0) {
      // captions checkbox changed
      if(!input.is(':checked')) {
        translationCheckboxes.prop('checked', false);
      }
    } else {
      // translation checkbox changed
      if(input.is(':checked')) {
        captionCheckbox.prop('checked', true);
      }
    }
    updateTotalAndTurnaroundTime();
  });

});

ajax.responseProcessors.replaceClientOrderForm = function(change, context) {
  var selection = calcFormSelection();
  // Replace content
  var container = $(change[1]);
  var content = $(change[2]);
  container.empty().append(content).updateBehaviors();
  // Restore form selection
  _.each(selection.languages, function(value) {
    var name = 'subtitles-subtitles_' + value.source + '_' + value.target;
    $('.orderPage-subtitlesFieldsets input[type=checkbox][name=' + name + ']').prop('checked', true);
  });

  $('input[name="subtitles-difficult_audio"]').prop('checked', selection.difficultAudio);
  $('input[name="subtitles-technical_language"]').prop('checked', selection.technicalLanguage);
  updateTotalAndTurnaroundTime();
}
