// Copyright 2019 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');
var _ = require('underscore');

var exampleSubs = [
    {
        project: 'Project 1',
        video: 'Video 1',
        video_id: 'aaaaaaaa',
        language_code: 'en',
    },
    {
        project: 'Project 1',
        video: 'Video 1',
        video_id: 'aaaaaaaa',
        language_code: 'es',
    },
    {
        project: 'Project 1',
        video: 'Video 2',
        video_id: 'bbbbbbbb',
        language_code: 'en',
    },
    {
        project: 'Project 2',
        video: 'Video 3',
        video_id: 'cccccccc',
        language_code: 'en',
    }
];

var replacementREs = {
    video: /\{video\}/g,
    video_id: /\{video_id\}/g,
    language_code: /\{language_code\}/g,
    project: /\{project\}/g,
    format: /\{format\}/g,
};

$.behaviors('.downloadSubtitles', function(container) {
    var formatCheckboxes = $('input[name=formats]', container);
    var pathTemplateElt = $('.presetOrCustom[data-name=path_template]', container);
    var example = $('.downloadSubtitles-example', container);
    var exampleFiles = $('.downloadSubtitles-exampleFiles', container);

    formatCheckboxes.on('change', updateExample);
    pathTemplateElt.on('presetOrCustomChange', updateExample);

    function updateExample() {
        var formats = formatCheckboxes.filter(':checked').map(function() { return this.value;})
        var pathTemplate = pathTemplateElt.presetOrCustom().value();

        if(formats.length > 0 && pathTemplate) {
            example.show();
        }
        var paths = calcExamplePaths(formats, pathTemplate);
        var rootDir = organizePathsIntoDirectories(paths);
        exampleFiles.empty();
        walkExampleDir(exampleFiles, rootDir, 0, rootDir.maxDepth)
    }

    function calcExamplePaths(formats, pathTemplate) {
        var paths = [];
        _.each(formats, function(format) {
            _.each(exampleSubs, function(sub) {
                var vars = {format: format};
                var path = pathTemplate;
                _.extend(vars, sub);
                _.each(replacementREs, function(re, name) {
                    path = path.replace(re, vars[name]);
                });
                paths.push(path);
            });
        });
        paths.sort();
        return paths;
    }

    // Object that represents a directory in the zip archive.  Used to figure
    // out what to display in the zip file example div.
    function makeDirectory() {
        return {
            directories: {}, // maps names to other directories
            files: [] // list of names
        }
    }

    // Given a list of paths, organize them by directory
    function organizePathsIntoDirectories(paths) {
        var rootDir = makeDirectory();
        rootDir.maxDepth = 0;

        _.each(paths, function(path) {
            var components = path.split('/');
            rootDir.maxDepth = Math.max(rootDir.maxDepth, components.length);
            var filename = components.pop();
            var directories = components;
            var currentDir = rootDir;

            _.each(directories, function(dirname) {
                if(currentDir.directories[dirname] === undefined) {
                    currentDir.directories[dirname] = makeDirectory();
                }
                currentDir = currentDir.directories[dirname];
            });
            currentDir.files.push(filename);
        });

        return rootDir;
    }

    function walkExampleDir(table, dir, currentDepth, maxDepth) {
        _.each(dir.directories, function(subdir, dirname) {
            addExampleFilesRow(table, currentDepth, maxDepth, dirname + '/');
            walkExampleDir(table, subdir, currentDepth + 1, maxDepth);
        });
        _.each(dir.files, function(filename) {
            addExampleFilesRow(table, currentDepth, maxDepth, filename);
        });
    }

    function addExampleFilesRow(table, currentDepth, maxDepth, filename) {
        var tr = $('<tr>');
        if(currentDepth) {
            tr.append($('<td>', {colspan: currentDepth}));
        }
        tr.append($('<td>').text(filename));
        if(maxDepth > currentDepth + 1) {
            tr.append($('<td>', {colspan: maxDepth - currentDepth - 1}));
        }
        table.append(tr);
    }
});
