var $ = require('jquery');
var _ = require('underscore');
var stripe = require('./stripe');
var ajax = require('../ajax');

$.behaviors('.paymentSubmit', function(button) {
  button = $(button);
  button.on('click', function() {
    //disable button after click to prevent extra clicks from user
    button.prop('disabled', true);
    submitPayment(button.data('handler'), button.data('handlerData'));
  });
})

function submitPayment(handler, handlerData) {
  var initial = true;
  var extraPostData = {};
  if(handlerData === undefined) {
    handlerData = {};
  }
  function postData() {
    var postData = {
      data: JSON.stringify(handlerData),
    }
    if(initial) {
      postData['initial'] = 1;
      initial = false;
    }
    _.extend(postData, extraPostData);
    ajax.post(handler, postData, {raw:true}).then(handlePaymentData, handleResponseError);
  }

  function handlePaymentData(data) {
    if(data.postData) {
      extraPostData = data.postData;
    }
    if(data.redirect) {
      // Used to handle non-stripe related issues that require us to navigate the user out of the payment handling loop.
      window.location = data.redirect;
      return;
    }
    if(data.payment.status == 'requires_action') {
      confirmCardPayment(data.payment.secret);
    } else if(data.payment.status == 'succeeded' || data.payment.status == 'requires_capture') {
      if(data.next) {
        window.location = data.next;
      } else {
        console.log('payment succeeeded, but no next property');
        console.log(data);
      }
    } else {
      console.log('unexpected payment status: ', data.payment.status);
      handlePaymentError()
    }

  }

  function confirmCardPayment(secret) {
    stripe.confirmCardPayment(secret).then(function(result) {
      if(result.error) {
        handlePaymentError(result.error.message);
      } else {
       postData();
      }
    });
  }

  function handleResponseError(e) {
    console.log('error when requesting payment confirmation: ', e);
    handlePaymentError();
  }

  function handlePaymentError(message) {
    if(!message) {
      message = gettext('Error submitting payment');
    }
    $('.paymentForm').trigger('set-error', message);
    fadeInForm();
    // re-enable purchase button on failure
    $('.paymentSubmit').prop('disabled', false);
  }

  function fadeInForm() {
    if($('.paymentForm').data('next') === undefined) {
      $('.paymentForm').data('next', function() {
        fadeInLoading();
       postData();
      });
    }
    $('.paymentPane-loading').fadeOut(200, function() {
      $('.paymentPane-form').fadeIn(200);
    });
  }

  function fadeInLoading() {
    $('.paymentPane-form').fadeOut(200, function() {
      $('.paymentPane-loading').fadeIn(200);
    });
  }

  ajax.update("/api/payments/payment-pane/", { keepState: true}).then(postData);
}

Object.assign(ajax.responseProcessors, {
  'submitPayment': function(change) {
    submitPayment(change[1], change[2]);
  },
});


module.exports = {
  submitPayment: submitPayment
};
