// Copyright 2020 Participatory Culture Foundation, All Rights Reserved
//
// autoFilter -- Wrap a filter field and perform an AJAX update on change
var $ = require('jquery');
var filters = require('./filters.js');

$.behaviors('.autoFilter', autoFilter);

function autoFilter(container) {
  $(container).on('change', function(evt) {
    var target = $(evt.target);
    filters.add(target.attr('name'), target.val());
  });
}
