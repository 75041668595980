/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2018 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

var $ = require('jquery');
var _ = require('underscore');
var position = require('./position');
var querystring = require('./querystring');
var select = require('./select/main');
var ajax = require('./ajax');
var filters = require('./filters');
var keyCodes = require('./keyCodes');

$.behaviors('.filterBox', filterBox);

$.fn.searchFilters = function(query, limit) {
    var func = $(this).data('searchFilters');
    if(func === undefined) {
        console.warn('filterBox not setup for element');
    } else {
        return func(query, limit);
    }
}

function filterBox(filterBox) {
    filterBox = $(filterBox);
    var dropdownMenu = $('.dropdownMenu', filterBox);
    var button = $('.filterBox-button', filterBox);
    var sourceFields = {}; // Map filter names to the form-group element for that filter
    var chooser = null;

    var filtersContainer = getOrCreateFiltersContainer();

    // create the clear all button
    var clearAllButton = $('<button class="filterBox-clearAllButton">').insertAfter(filtersContainer);
    clearAllButton.text(gettext('Clear'));
    clearAllButton.on('click', filters.clear);

    dropdownMenu.on('link-activate', function(evt, fieldName, extra) {
        if(extra === undefined) {
            var options = {};
        } else {
            var options = _.object(_.map(extra.split(','), function(name) { return [name, true]}));
        }
        if(fieldName == 'update-filter') {
            // menu item that updates the filter without needing a dialog
            return;
        }
        buildChooser(fieldName, filterBox, evt.isAutocompleteFilter, options);
    }).on('show', function() {
        removeChooserIfShown();
    });

    function getOrCreateFiltersContainer() {
        var filtersContainer = $('.filterBox-filters', filterBox);
        if(filtersContainer.length == 0) {
            filtersContainer = $('<div class="filterBox-filters">').insertAfter(button);
        }
        return filtersContainer;
    }

    function buildChooser(fieldName, positioner, is_autocomplete_filter, options) {
        removeChooserIfShown();

        var formField = getSourceField(fieldName).clone();
        var input = $('[name=' + fieldName + ']', formField);
        var isSelect2 = Boolean(input.is('.select'));
        var isDateTime = Boolean(input.is('.datetime-input'))
        var formLabel = formField.children('label');

        chooser = $('<div class="filterBox-chooser">');
        if(options.wide) {
            chooser.addClass('wide');
        }
        if(formLabel.length > 0) {
            // convert label to a title for the dialog
            chooser.append($('<h4>', { 'class': 'modal-title'}).text(formLabel.text()));
            formLabel.remove();
        }
        chooser.append($('<div class="filterBox-chooserField">').append(formField));
        var buttonContainer = $('<div class="filterBox-chooserActions">').appendTo(chooser);
        var cancelButton = $('<button class="filterBox-chooserAction secondary">').text(gettext('Cancel')).appendTo(buttonContainer);
        var applyButton = $('<button class="filterBox-chooserAction cta">').text(gettext('Apply')).appendTo(buttonContainer);
        $('body').append(chooser);

        setupChooserInput(fieldName, input, isSelect2);
        cancelButton.click(removeChooserIfShown);
        applyButton.click(function() {
            removeChooserIfShown();
            if(is_autocomplete_filter) {
                updateSourceChoices(fieldName, input);
            }
            var values = calcValueList(input);
            filters.add(fieldName, values);
        });
        input.on('input change', function() {
            if($(this).val() == '') {
                applyButton.prop('disabled', true);
                applyButton.addClass('disabled');
            } else {
                applyButton.prop('disabled', false);
                applyButton.removeClass('disabled');
            }
        }).change();

        chooser.updateBehaviors();
        position.below(chooser, positioner);
        if(isSelect2) {
            input.select2('focus');
        } else {
            input.focus();
        }
        if(input.is(':text')) {
            input.on('keydown', function(evt) {
                if(evt.which == keyCodes.enter && input.val() != '') {
                    applyButton.click();
                } else if(evt.which == keyCodes.esc) {
                    removeChooserIfShown();
                }
            });
        }

        // For some reason, flatpickr renders two fields when used in filterboxes with alt input
        if (isDateTime) {
            if ($(input).siblings().length > 1) {
                $(input).siblings(':last').hide();
            }
        }
    }

    // Update the choices for a source field after a user selects something in
    // the chooser
    //
    // This method handles this edge case:
    //   - We have an AJAX select on the form
    //   - A user open the chooser with that select
    //   - They then type something in the textbox, select a choice, and click the Apply button
    //   - At this point, in labelForInputValue() we try to figure out the label for the choice.
    //   - However, this fails because the choice isn't in the source input,
    //     since it was added by the AJAX code
    function updateSourceChoices(fieldName, clonedInput) {
        var sourceInput = $('[name=' + fieldName + ']', getSourceField(fieldName));

        var selectedOption = clonedInput.find('option:selected').addClass('from-cloned-input');

        // remove inputs added from previous updateSourceChoices() calls
        sourceInput.find('.from-cloned-input').remove();
        // add the selected option
        sourceInput.append(selectedOption.clone());
    }

    // Get the form-group for a filter.
    //
    // This method removes the field from the DOM before returning it, which is
    // required because we want to use a clone of the source field in the
    // chooser box.  If we didn't remove the element, then we would have
    // duplicate DOM ids.
    function getSourceField(name) {
        if(sourceFields[name] === undefined) {
            var sourceInput = $('[name=' + name + ']', filterBox);
            if(sourceInput.is('.select')) {
                sourceInput.select2('destroy');
            }
            var formField = sourceInput.closest('.form-group').remove();
            sourceFields[name] = formField;
        }
        return sourceFields[name];
    }

    function setupChooserInput(name, input, isSelect2) {
        // Pre-select the currently selected value for singletons
        if(inputIsMultiValued(input)) {
            var currentVal = querystring.parseList()[name];
        } else {
            var currentVal = querystring.parse()[name];
        }
        if(currentVal) {
            setCurrentValue(input, currentVal);
            // for textboxes, select the value as well
            input.filter(':text').select();
        }

        if(isSelect2) {
            select.initSelect(input);
        }
    }

    function setCurrentValue(input, currentVal) {
        if(inputIsRadio(input)) {
            input.prop('checked', function() {
                return this.value == currentVal;
            });
        } else if(inputIsCheckbox(input)) {
            input.prop('checked', function() {
                return currentVal.indexOf(this.value) != -1;
            });
        } else {
            input.val(currentVal);
        }
    }

    function removeChooserIfShown() {
        if(chooser) {
            chooser.remove();
            chooser = null;
        }
    }

    function calcValueList(input) {
        if(inputIsRadio(input)) {
            var checked = input.filter(':checked');
            if(checked.length > 0) {
                return [checked.val()];
            } else {
                return [];
            }
        } else if(inputIsCheckbox(input)) {
            return input.filter(':checked').map(function() { return this.value}).toArray();
        } else if(inputIsMultiValued(input)) {
            return input.val();
        } else {
            return [input.val()];
        }
    }

    filters.callbacks.add(function(change, name, value) {
        if(change == 'add') {
            updateFilterBox(name);
        } else if(change == 'remove') {
            updateFilterBox(name);
        } else if(change == 'clear') {
            clearAllFilterBoxes();
        } else {
            console.warn('Unknown filters callback: ', change)
        }
    });

    function updateFilterBox(name) {
        var query = querystring.parseList()
        var values = query[name] || [];
        removeFilterBox(name);
        if(values.length > 0) {
            createFilterBox(name, values)
        }
    }

    function createFilterBox(name, values) {
        var inputLabel = labelForInput(name);
        if(inputLabel === null) {
            return;
        }
        var nonDefaultValues = _.filter(values, function(value) {
            // Don't add the filter box if the value is the default value
            return !(filterBox.data(name + 'Default') == value || value == '');
        });
        if(nonDefaultValues.length > 0) {
            var label = labelForInputValues(name, nonDefaultValues);

            var elt = $('<div class="filterBox-filter">');
            elt.append($('<span class="filterBox-filterText">').text(inputLabel + ': ' + label));
            var closeButton = $('<button class="filter-removeFilter">x</button>').appendTo(elt);
            elt.data({ name: name });
            closeButton.on('click', function() { filters.remove(name); });

            filtersContainer.append(elt);
        }
        updateHasFilters();
    }

    function removeFilterBox(name) {
        filtersContainer.children().each(function() {
            var elt = $(this);
            if(elt.data('name') == name) {
                elt.remove();
            }
        });
        updateHasFilters();
    }

    function clearAllFilterBoxes() {
        filtersContainer.empty();
        updateHasFilters();
    }

    function inputIsMultiValued(input) {
        var type = input.prop('type');
        if(type == 'select-multiple' || type == 'checkbox') {
            return true;
        } else {
            return false;
        }
    }

    function inputIsRadio(input) {
        return input.prop('type') == 'radio';
    }

    function inputIsCheckbox(input) {
        return input.prop('type') == 'checkbox';
    }

    function labelForInput(name) {
        var label = null;

        $('.dropdownMenu-link', dropdownMenu).each(function() {
            var link = $(this);
            var activateArgs = link.data('activateArgs');
            if(activateArgs) {
                if(activateArgs[0] == name) {
                    label = link.text();
                    return false;
                } else if(activateArgs && activateArgs[0] == 'update-filter' && activateArgs[1] == name) {
                    label = activateArgs[3];
                    return false;
                }
            }
        });

        return label;
    }

    function labelForInputValues(name, values) {
        if(values.length == 1) {
            return labelForInputValue(name, values[0]);
        } else {
            return interpolate(gettext('%(count)s selected'), {count: values.length}, true);
        }
    }

    function labelForInputValue(name, value) {
        var sourceField = getSourceField(name);
        var input = $('[name=' + name + ']', sourceField);
        if(input.length == 0) {
            return gettext('Invalid');
        }
        else if(input.prop('tagName') == 'SELECT') {
            var selectedOption = $('option[value="' + value + '"]:first', input);
            if(selectedOption.length) {
                return selectedOption.text();
            } else if (sourceField.hasClass('select-return-value')) {
                // project is either a radio group or dropdown depending on number of items
                // return value so that it acts the same as the radio group
                // when given an invalid option
                return value;
            } else {
                return gettext('Invalid');
            }
        } else if(sourceField.find('.tableSelect').length) {
            var selectedRow = input.filter('[value="' + value + '"]');
            return selectedRow.closest('tr').find('td:not(.tableSelect-checkboxColumn)', sourceField).first().text();
        } else if(inputIsRadio(input)) {
            var selected = input.filter('[value="' + value + '"]', sourceField);
            var label = $('label[for=' + selected.attr('id') + ']', sourceField)
            if(label.length > 0) {
                return label.text();
            }
        } else if(input.is('.datetime-input') && Date.parse(value)) {
            return (new Date(value).toLocaleString(navigator.language,
                { month: 'short',
                day: 'numeric',
                hour:'numeric',
                minute: 'numeric' }
            ))
        }
        return value;
    }

    function updateHasFilters() {
        if(filtersContainer.is(':empty')) {
            filterBox.removeClass('hasFilters');
        } else {
            filterBox.addClass('hasFilters');
        }
    }

    // Add existing querystring args as filters
    _.each(querystring.parseList(), function(values, name) { createFilterBox(name, values) });

    // Search all filters to find filters that match the query.
    function searchFilters(sourceFields, query, limit) {
        if(limit === undefined) {
            limit = 6;
        }
        query = query.toLowerCase();
        var fields = _.map(sourceFields, getSourceField);
        var matches = [];
        function checkForMatch(fieldName, value, text) {
            text = text.toLowerCase();
            if(value != '' && text.indexOf(query) != -1) {
                matches.push({
                    name: fieldName,
                    value: value,
                    text: text,
                    label: labelForInput(fieldName)
                });
            }
        }

        _.each(fields, function(field) {
            $('select', field).each(function() {
                var select = $(this);
                var fieldName = select.attr('name');
                $('option', this).each(function() {
                    var option = $(this);
                    checkForMatch(fieldName, option.val(), option.text());
                });
            });
            $('input[type=radio]', field).each(function() {
                var radio = $(this);
                var label = radio.siblings('label');
                checkForMatch(radio.attr('name'), radio.val(), label.text());
            });
        });
        return pickMatches(matches, query, limit);
    };

    function pickMatches(matches, query, limit) {
        // First step, add a score value to each match.  Right now we simply have 3 possible scores:
        _.each(matches, function(match) {
            if(match.text == query) {
                // exact match
                match.score = 0;
            } else if(match.value == query) {
                // exact match on the value
                match.score = 1;
            } else if(match.text.indexOf(query) == 0) {
                // query is a prefix of the text
                match.score = 2;
            } else {
                // everything else
                match.score = 3;
            }
        });

        // Comparison function -- orders matches based on score, then by
        // fieldname, so that matches for the same field get grouped together.
        function matchCompare(a, b) {
            if(a.score != b.score) {
                return a.score - b.score;
            } else if(a.name < b.name) {
                return -1;
            } else if(a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        }

        // Group matches by the field name and sort the arrays
        var matchesByField = {};
        _.each(matches, function(match) {
            if(match.name in matchesByField) {
                matchesByField[match.name].push(match);
            } else {
                matchesByField[match.name] = [match];
            }
        });
        _.each(_.values(matchesByField), function(matches) {
            matches.sort(matchCompare);
        });

        // Take matches from each field in turn
        var finalMatches = [];
        _.each(_.zip.apply(this, _.values(matchesByField)), function(oneMatchFromEachField) {
            _.each(oneMatchFromEachField, function(match) {
                if(match !== undefined) {
                    finalMatches.push(match);
                }
            });
        });

        // Finally, truncate the array, sort it 1 more time, then return it.
        finalMatches = finalMatches.slice(0, limit);
        finalMatches.sort(matchCompare);
        return finalMatches;
    }

    filterBox.data('searchFilters', searchFilters);
}
