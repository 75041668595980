const cookies = require('browser-cookies')

$.behaviors("div.asr-intro", function (element){
    let c = cookies.get("hideASRCallout")
    if (c){
        element.classList.add("hidden")
    }
    let btn = element.querySelector("button")
    if (btn) {
        btn.addEventListener("click", function(){
        cookies.set("hideASRCallout", "true")
        element.classList.add("hidden")
    })}
})
