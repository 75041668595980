/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2017 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

var $ = require('jquery');
var dialogs = require('../shared/dialogs');

$.behaviors('#videourl-dropdown', videourlDropdown);

function videourlDropdown(menu) {
    menu = $(menu);
    var listview = $('.listView.videoUrls');
    var openerButton = null;

    menu.on('show', function(evt, data) {
        var makePrimaryButton = $('.videourl-make-primary .dropdownMenu-link', data.dropdownMenu.menu);
        var deleteButton = $('.videourl-delete .dropdownMenu-link', data.dropdownMenu.menu);

        if(data.button) {
            openerButton = data.button;
        } else if(data.showData && data.showData.row !== undefined) {
            openerButton = $('.dropdownMenu-button', listview).eq(data.showData.row);
        } else {
            console.log("videourlDropdown: can't calculate button");
            return false;
        }
        if(openerButton.data('primary')) {
            makePrimaryButton.addClass('disabled').prop('disabled', true);
            deleteButton.addClass('disabled').prop('disabled', true);
        } else if(openerButton.data('original')) {
            makePrimaryButton.removeClass('disabled').prop('disabled', false);
            deleteButton.addClass('disabled').prop('disabled', true);
        } else {
            makePrimaryButton.removeClass('disabled').prop('disabled', false);
            deleteButton.removeClass('disabled').prop('disabled', false);
        }
    });

    // Map link actions to the dialogs they open
    var dialogMap = {
        "make-primary": "make-url-primary-dialog",
        "delete": "delete-url-dialog"
    };
    menu.on('link-activate', function(evt, action) {
        var dialog = $('#' + dialogMap[action]);
        dialogs.showModal(dialog);
        $('input[name=id]', dialog).val(openerButton.data('videourl'));
        $('.modal-header', dialog).text(openerButton.data('url'));
    });
}


function calculateDaysUntilNextMonth() {
    const today = new Date();
    const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    const timeDifference = firstDayOfNextMonth - today;
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));

    // Determine the text based on the number of days
    let text;
    if (daysLeft === 1) {
        text = `Free minutes reset in: <strong>${daysLeft} day</strong>. Need more now?`;
    } else {
        text = `Free minutes reset in: <strong>${daysLeft} days</strong>. Need more now?`;
    }

    // Update the target element with the result
    const resultElement = document.getElementById('result');
    if (resultElement) {
        resultElement.innerHTML = text;

        // Set the font weight for the strong element
        const strongElement = resultElement.querySelector('strong');
        if (strongElement) {
            strongElement.style.fontWeight = '900';
        }
    }
}

// Add an event listener to run the calculation when the page is fully loaded
window.addEventListener('load', calculateDaysUntilNextMonth);
$(document).ready(function() {
    // Handle specific Google Drive link
    $('.dropdownMenu-item.gdrive .dropdownMenu-link').on('keydown', function(event) {
        if (event.key === 'Enter' || event.keyCode === 13) {
            var dataHref = $(this).attr('href');
            if (dataHref) {
                window.location.href = dataHref;
            }
        }
    });

    // Handle general .dropdownMenu-link.ajaxLink
    $('.dropdownMenu-link.ajaxLink').on('keydown', function(event) {
        if (event.key === 'Enter' || event.keyCode === 13) {
            var dataHref = $(this).data('href');
            if (dataHref) {
                window.location.href = dataHref;
            }
        }
    });
});

var setTabIndexAndFocus = function() {
    var addVideosDialog = $('#add-videos-dialog');

    // Check if the dialog is present in the DOM
    if (addVideosDialog.length > 0) {
        // Set the tabindex of the modal to 0
        addVideosDialog.attr('tabindex', '0');

        // Find the visible and displayed select elements with the class .languagesSetup
        var selectElements = addVideosDialog.find('span.select2-selection');

        // Check if any visible select element is found
        if (selectElements.length > 0) {
            // Set tabindex to 0 for each visible select element
            selectElements.attr('tabindex', '0');
            // Focus on the first visible select element
            selectElements.eq(0).focus();
        }
    }
};

