// Copyright 2020 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

$.behaviors('.radioItem-subfield', function(field) {
  field = $(field);
  var input = field.find(':input');
  var label = field.find('label');
  var formGroup = field.find('.form-group');
  var radio = field.siblings('[type=radio]');
  var allRadios = $('input[name=' + radio.attr('name') + ']');

  function updateDisabled() {
    var checked = radio.is(':checked');
    label.toggleClass('disabled', !checked);
    input.prop('disabled', !checked);
    if(field.hasClass('slideDown')) {
      if(checked) {
        field.slideDown();
      } else {
        field.slideUp();
      }
    }
    formGroup.trigger('set-inputs-disabled', !checked);
  }

  allRadios.on('change', updateDisabled);
  updateDisabled();
});
