// Copyright 2020 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');
var position = require('./position');

$.behaviors('.amaraTooltip', function(elt) {
  elt = $(elt);
  var target = $('#' + elt.data('target'));
  var overTooltip = false, overElt = false, wasOverElt = false, eltVisible = false;

  target.hover(function() {
    overTooltip = true;
    updateVisibility();
  }, function() {
    overTooltip = false;
    updateVisibility();
  });

  elt.hover(function() {
    overElt = true;
    updateVisibility();
  }, function() {
    overElt = false;
    updateVisibility();
  });

  function updateVisibility() {
    setTimeout(doUpdateVisibility, 100);
  }
  function doUpdateVisibility() {
    // We consider the user hovering in either of these cases:
    //   - They are corrently over the element
    //   - They were over the element last doUpdateVisibility check, and now they are over the tooltip
    var hovering = overElt || (wasOverElt && overTooltip);
    if(hovering && !eltVisible) {
      position.below(target, elt, {dropLeft: true});
      target.fadeIn(200);
      eltVisible = true;
    } else if(!hovering && eltVisible) {
      target.fadeOut(200);
      eltVisible = false;
    }
    wasOverElt = overElt;
  }
});
