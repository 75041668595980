
var $ = require('jquery');

$.behaviors('.ruleTable-checkbox input', function(container) {
  var checkbox = $(container);
  function toggleFields() {
    var td = checkbox.closest('td');
    var otherInputs = $('select, input', td).not(checkbox);
    if(!checkbox.prop('checked')) {
      otherInputs.prop('disabled', true);
      otherInputs.css('background-color', '#eee')
    } else {
      otherInputs.prop('disabled', false);
      otherInputs.css('background-color', '#fff')
    }
  }

  $('.ruleTable-rate span.fieldOptional').hide()
  checkbox.on('change', toggleFields);
  toggleFields();
});
