// Copyright 2019 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');
var _ = require('underscore');
var filters = require('./filters');
var filterBox = require('./filterBox');
var dropdown = require('./dropdown');
var keyCodes = require('./keyCodes');

$.behaviors('.manageRequestsView', manageRequestsView);

function manageRequestsView(body) {
    var searchBar = $('.contentHeader-searchBar');
    var filterBox = $('.filterBox');

    var menu = dropdown.create('searchbar-menu', [], {noIcon: true});
    menu.on('focus-button', function() {
        searchBar.focus();
        return false;
    })
    menu.on('link-activate', function(evt, name, value) {
        filters.add(name, value);
        searchBar.val('');
    });

    searchBar.on('activate', function(evt, query) {
        return false;
    }).on('input focus', function(evt) {
        var query = searchBar.val();
        if(query.length >= 2) {
            var matches = filterBox.searchFilters(['language', 'state', 'video_language', 'project'], searchBar.val());
            matches.push({
                label: gettext('Video search'),
                text: query,
                name: 'q',
                value: query
            });
        } else {
            matches = [];
        }
        menu.dropdown('setItems', _.map(matches, function(match) {
            var content = $(document.createDocumentFragment());
            content.append($('<span class="text-teal">').text(match.label + ': '));

            var startPos = match.text.toLowerCase().indexOf(query.toLowerCase());
            var beforeQuery = match.text.substring(0, startPos);
            var afterQuery = match.text.substring(startPos + query.length);
            if(beforeQuery) {
                content.append(beforeQuery);
            }
            content.append($('<strong>').text(query));
            if(afterQuery) {
                content.append(afterQuery);
            }

            return {
                content: content,
                args: [match.name, match.value]
            };
        }));
        menu.dropdown('show', {
            anchor: searchBar,
            positionOptions: {fullWidth: true},
            ignoreClick: function(evt, target) {
                // Don't close the menu when the user clicks in the searchbar
                return target.closest(searchBar).length != 0;
            }
        });
    }).on('keydown', function(evt) {
        if(evt.which == keyCodes.enter ||
                evt.which == keyCodes.down) {
            $('#searchbar-menu').dropdown('focusFirstLink');
            evt.preventDefault();
        } else if(evt.which == keyCodes.up) {
            $('#searchbar-menu').dropdown('focusLastLink');
            evt.preventDefault();
        }
    });
}
