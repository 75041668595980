//Uploader form controls
var $ = require('jquery');
var form_val = require('../shared/formValidation'); //need to find out why this does not work

function addClientSideFieldErrorU(input) {
  var formGroup = input.closest('.form-group');
  if(!input.hasClass('client-validation-error')) {
      formGroup.data({
          hadError: input.hasClass('has-error'),
          oldHelpBlock: formGroup.find('.helpBlock').remove()
      });
      formGroup.addClass('has-error client-validation-error');
      formGroup.append($('<div class="helpBlock"></div>').text('this field is required'));
  } else {
      formGroup.find('.helpBlock').text('this field is required');
  }
}

function removeClientSideFieldErrorU(formGroup) {
  var formGroupData = formGroup.data();
  if(!formGroupData.hadError) {
      formGroup.removeClass('has-error');
  }
  formGroup.find('.helpBlock').remove();
  if(formGroupData.oldHelpBlock) {
      formGroup.append(formGroupData.oldHelpBlock);
  }
  formGroup.removeClass('client-validation-error');
  formGroup.removeData(['hadError', 'oldHelpBlock']);
}

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = jQuery.trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
var csrftoken = getCookie('csrftoken');
function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader("X-CSRFToken", csrftoken);
    }
  }
});

function get_select_val(elem) {
  if (elem.selectedIndex >= 0) {
    return elem.options[elem.selectedIndex].value;
  }
  else {
    return '';
  }
}

function showMessage(type, msg_pref, msg_body) {
  html = '<div class="alert alert-' + type + ' alert-dismissible" role="alert">\
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\
      <span aria-hidden="true">&times;</span></button>\
    <strong>'+ msg_pref + '!</strong> ' + msg_body + '.\
  </div>';
  $('.errorlist').append(html);
}

var uploaded_files_counter = 0;
var uploaded_files_count_actual = 0; //keep actual count of files to be uploaded
var fileItemList = [];
var notAllowedFiles = new Array();
var language = '';
var team = '';
var project = '';

$(document).on('click', '#upload_files', function (event) {
  //We iterate through each file and if it is valid we proceed with the upload
  event.preventDefault();

  //pick form values
  form = document.getElementById('video-uploader');
  language = get_select_val(form.elements['language']);
  team = get_select_val(form.elements['team']);
  project = get_select_val(form.elements['project']);
  selectedFiles = $('input[name=video_file').prop('files');
  // use addClientSideFieldError imported from formValidation module but this
  // does not work
  // form_val.addClientSideFieldError(form.elements['language']);

  //form validation
  if (language == '$clear' || language.length == 0) {
    addClientSideFieldErrorU($('#id_language')); return;
  }
  if (team == '$clear' || team.length == 0) {
    addClientSideFieldErrorU($('#id_team')); return;
  }
  if (selectedFiles.length < 1) {
    addClientSideFieldErrorU($('input[name=video_file')); return;
  }
  uploaded_files_counter = 0; //reset
  $.each(selectedFiles, function (index, item) {
    var myFile = verifyFileIsImageMovieAudio(item)
    if (myFile) {
      uploadFile(myFile);
      uploaded_files_counter++;
    } else {
      //add a way to show user the error message for each invalid file
      // like disappearing notifications
      showMessage('error', 'Error', 'invalid file <' + item.name + '> added');
    }
  });
  uploaded_files_count_actual = uploaded_files_counter;
  //reset form
  //document.getElementById('video-uploader').reset();
  // remove form errors if any
  $('#video-uploader').find('.client-validation-error').each(function() {
    removeClientSideFieldErrorU($(this));
  });

});

function verifyFileIsImageMovieAudio(file) {
  // quick check of uploaded file extension
  var extension = file.name.split('.').pop().toLowerCase();
  switch (extension) {
    case 'mov':
    case 'mp4':
    case 'ogv':
    case 'webm':
    case 'mpeg4':
    case 'avi':
      return file
    case 'mp3':
      return file
    default:
      notAllowedFiles.push(file)
      return null
  }
};

function constructFormPolicyData(policyData, fileItem) {
  var contentType = fileItem.type != '' ? fileItem.type : 'application/octet-stream'
  var url = policyData.url
  var filename = policyData.filename
  var repsonseUser = policyData.user
  // var keyPath = 'www/' + repsonseUser + '/' + filename
  var keyPath = policyData.file_bucket_path
  var fd = new FormData()
  fd.append('key', keyPath + filename);
  fd.append('acl', 'public-read');
  fd.append('Content-Type', contentType);
  fd.append("AWSAccessKeyId", policyData.key)
  fd.append('Policy', policyData.policy);
  fd.append('filename', filename);
  fd.append('Signature', policyData.signature);
  fd.append('file', fileItem);
  return fd
}

function fileUploadComplete(fileItem, policyData) {
  data = {
    uploaded: true,
    file_size: fileItem.size,
    file_type: fileItem.type,
    file_id: policyData.file_id,
    language: language,
    team: team,
    project: project
  };
  $('.loading-bar').show();
  $.ajax({
    type: "POST",
    data: data,
    url: "/api/uploader/complete/",
    success: function (data) {
      displayItems(fileItemList);
      $('.loading-bar').hide();
    },
    error: function (jqXHR, textStatus, errorThrown) {
      showMessage('error', 'Error', 'uploader:complete - error contacting server');
      $('.loading-bar').hide();
    }
  })
}

function displayItems(fileItemList) {
  var itemList = $('.item-loading-queue')
  itemList.html("")
  $.each(fileItemList, function (index, obj) {
    var item = obj.file;
    var id_ = obj.id;
    var order_ = obj.order;
    var html_ = "<div class=\"progress\">" +
      "<div class=\"progress-bar\" role=\"progressbar\" style='width:" + item.progress + "%' aria-valuenow='" + item.progress + "' aria-valuemin=\"0\" aria-valuemax=\"100\"></div></div>"
    itemList.append("<div>" + order_ + ") " + item.name + " <br/>" + html_ + "<hr/></div>")

  })
}

function uploadFile(fileItem) {
  var policyData;
  var newLoadingItem;
  // get AWS upload policy for each file uploaded through the POST method
  // Remember we're creating an instance in the backend so using POST is
  // needed.
  $('.loading-bar').show();
  $.ajax({
    type: "POST",
    data: {
      filename: fileItem.name
    },
    url: "/api/uploader/policy/",
    success: function (data) {
      policyData = data;
      $('.loading-bar').hide();
    },
    error: function (data) {
      showMessage('error', 'Error', "uploader:policy - An error occured while contacting the server");
      $('.loading-bar').hide();
    }
  }).done(function () {
    // construct the needed data using the policy for AWS
    var fd = constructFormPolicyData(policyData, fileItem)

    // use XML http Request to Send to AWS.
    var xhr = new XMLHttpRequest()

    // construct callback for when uploading starts
    xhr.upload.onloadstart = function (event) {
      var inLoadingIndex = $.inArray(fileItem, fileItemList)
      if (inLoadingIndex == -1) {
        // Item is not loading, add to inProgress queue
        newLoadingItem = {
          file: fileItem,
          id: policyData.file_id,
          order: fileItemList.length + 1
        }
        fileItemList.push(newLoadingItem)
      }
      fileItem.xhr = xhr
    }

    // Monitor upload progress and attach to fileItem.
    xhr.upload.addEventListener("progress", function (event) {
      if (event.lengthComputable) {
        var progress = Math.round(event.loaded / event.total * 100);
        fileItem.progress = progress
        displayItems(fileItemList); //pass default arg to end display items when progress is 100%
      }
    })

    xhr.upload.addEventListener("load", function (event) {
      // handle FileItem Upload being complete.
      fileUploadComplete(fileItem, policyData);
      uploaded_files_counter--;
      if(uploaded_files_counter == 0){
        // message will only be shown if all the files have been successfully uploaded
        showMessage('success', 'Success', "successfully uploaded "+ uploaded_files_count_actual + " video(s) to S3");
      }
    });
    // send the files to S3 endpoint
    xhr.open('POST', policyData.url, true);
    xhr.send(fd);
  })
}
$("#video-uploader #id_team").on('change', function () {
  //populate project field select options on team change event
  selected_team = $(this).val();
  var url = "/api/uploader/" + selected_team + "/projects/";
  $.getJSON(url, function (data) {
    project_field = $('#id_project');
    project_field[0].options.length = 0; //clear select options
    project_field.append('<option value="">No project</option>');
    $.each(data, function (index, value) {
      project_field.append('<option value="' + value.slug + '">' + value.name + '</option>');
    });
  }).fail(function(){
    project_field = $('#id_project');
    project_field[0].options.length = 0;
    project_field.append('<option value="">No project</option>');
  });
});

function dropHandler(ev) {
  console.log("File(s) dropped");

  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();

  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    [...ev.dataTransfer.items].forEach((item, i) => {
      // If dropped items aren't files, reject them
      if (item.kind === "file") {
        const file = item.getAsFile();
        console.log(`… file[${i}].name = ${file.name}`);
      }
    });
  } else {
    // Use DataTransfer interface to access the file(s)
    [...ev.dataTransfer.files].forEach((file, i) => {
      console.log(`… file[${i}].name = ${file.name}`);
    });
  }
}

function getFileName()
  {
      var x = document.getElementById('id_video_file')
      document.getElementById('fileName').innerHTML = x.value.split('\\').pop()
  }
