var $ = require('jquery');
var _ = require('underscore');
var zxcvbn = require('zxcvbn');


$.behaviors('.passwordStrength', handlePasswordStrength);

function handlePasswordStrength(container) {
    container = $(container);

    var score;
    var meterBoxes = container.find('.passwordStrength-meterBox');
    var input = $('#' + container.data('input'));
    var otherInputs = $();
    if(container.data('otherInputs')) {
        _.each(container.data('otherInputs').split(' '), function(inputId) {
            otherInputs = otherInputs.add('#' + inputId);
        });
    }
    if(container.data('otherValues')) {
        var otherValues = container.data('otherValues').split(' ');
    } else {
        var otherValues = [];
    }
    input.on('change keyup paste', updatePasswordMeter);

    function updatePasswordMeter() {
        var value = input.val();
        var valueMatchesOtherInput = false;

        if(otherValues.indexOf(value) != -1) {
            valueMatchesOtherInput = true;
        }

        if(!valueMatchesOtherInput) {
            otherInputs.each(function() {
                if($(this).val() == value) {
                    valueMatchesOtherInput = true;
                    return false;
                }
            });
        }

        var passwordHelpText;

        if (value == '') {
            score = -1;
            passwordHelpText = '';
        } else if (valueMatchesOtherInput) {
            score = -2;
            passwordHelpText = gettext('Password cannot be the same as username or email address.');
        } else {
            var zxcvbnPassword = zxcvbn(value);
            score = zxcvbnPassword.score;
            if (zxcvbnPassword.feedback.warning) {
                passwordHelpText = gettext(zxcvbnPassword.feedback.warning);
            } else if (zxcvbnPassword.feedback.suggestions.length > 0) {
                passwordHelpText = gettext(zxcvbnPassword.feedback.suggestions[0]);
            }
        }
        container.toggleClass('shown', value != '');
        var strengthText;
        var meterColor;
        var passwordHelpTextColor = '#222626'
        switch (score) {
            case -2:
                strengthText = '';
                passwordHelpTextColor = '#F15068'
                break;
            case -1:
                strengthText = '';
                break;
            case 0:
                strengthText = gettext('Bad');
                meterColor = '#cc3300';
                passwordHelpTextColor = '#f3254d'
                break;
            case 1:
                strengthText = gettext('Weak');
                meterColor = '#F18950';
                break;
            case 2:
                strengthText = gettext('Average');
                meterColor = '#F4A173';
                break;
            case 3:
                passwordHelpText = gettext('Your password is strong enough.')
                strengthText = gettext('Good');
                meterColor = '#8DC451';
                break;
            case 4:
                passwordHelpText = gettext('Your password is great! Good job.')
                strengthText = gettext('Strong');
                meterColor = '#80BD3E'
                break;
        }
        container.find(".passwordStrength-helpText").css("color", passwordHelpTextColor)
        container.find('.passwordStrength-helpText').text(passwordHelpText);
        if(strengthText) {
            container.find('.passwordStrength-meterText').show();
            container.find('.passwordStrength-meterTextValue').text(strengthText);
        } else {
            container.find('.passwordStrength-meterText').hide();
        }
        for(var i=0; i < meterBoxes.length; i++) {
            if (i <= score) {
                $(meterBoxes[i]).css("background-color", meterColor);
            }
            else {
                $(meterBoxes[i]).css("background-color", '');
            }
        }

        container.data('score', score);
        container.trigger('password-score-change', score);
    }
    updatePasswordMeter();
}
