// Copyright 2020 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

$.behaviors('.selectionGroup', function(container) {
    var $container = $(container);
    var inputName = $container.data('inputName');
    if(!inputName) {
        inputName = 'selection';
    }
    var checkboxSelector = ':checkbox[name=' + inputName + ']';

    function otherInputs() {
        return $(checkboxSelector).not(function(i, elt) {
            return $.contains(container, elt);
        });
    }
    $container.on('change', function(evt) {
        var input = $(evt.target);
        if(input.is(checkboxSelector) && input.is(':checked')) {
            otherInputs().filter(':checked').prop('checked', false).trigger('change');
        }
    });

});
