// Copyright 2020 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

$.behaviors('.expandableFieldset', function(fieldset) {
  fieldset = $(fieldset);
  var legend = fieldset.children('legend');
  var button = fieldset.find('.expandableFieldset-button');
  var content = fieldset.find('.expandableFieldset-content');

  legend.on('click', function(evt) {
    if(fieldset.is('.expanded')) {
      fieldset.removeClass('expanded');
      content.slideUp(100);
      button.find('.fas').attr('class', 'fas fa-caret-down');
    } else {
      fieldset.addClass('expanded');
      content.slideDown(100);
      button.find('.fas').attr('class', 'fas fa-caret-up');
    }
    evt.preventDefault();
  });
});
