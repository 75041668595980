/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2018 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

// actionBar -- bar with buttons to perform bulk actions that appears at the
// bottom of the screen
//
// Note that there's also some code in application/selectList.js to handle
// this.  That code is deprecated.  This code works standalone, it's typically
// paired with listView, but could also be used without it.

let $ = require('jquery');

$.behaviors('.actionBar', actionBar);

function actionBar(elt) {
    elt = $(elt);
    let inputName = elt.data('inputName') || 'selection';
    let container = elt.data('container');
    if(container) {
        container = $(container)[0];
    } else {
        container = document;
    }
    let form = $('.actionBar-form', elt);
    let selection = $('<input>', {
        type: 'hidden',
        name: inputName
    }).appendTo(form);

    // Note: This needs to by dynamic, since the inputs can change through AJAX
    // updates.
    function checkboxes() {
        return $('input[name=' + inputName + ']', container);
    }

    $.behaviors('input[name=' + inputName + ']', function(input) {
        if($.contains(container, input)) {
            $(input).change(updateActionBar);
            updateActionBar();
        }
    });

    $('.actionBar-deselect', form).click(function(evt) {
        checkboxes().prop('checked', false).change();
        evt.preventDefault();
    });

    // function oneItemOnly(){
    //     let selectedItems = getSelection();
    //     let flaggedElements = document.querySelectorAll('form.actionBar-form > button.oneItemOnly-flag')
    //     if (selectedItems.length > 1) {
    //         // for each ManagementForm check for oneItemOnly marker and disable button
    //         if (flaggedElements) {
    //             for (const el of flaggedElements) {
    //                 el.toggleAttribute("disabled")
    //                 el.classList.toggle("disabled")
    //             }
    //         }
    //     }
    //     else if (selectedItems.length === 1){
    //         if (flaggedElements) {
    //             for (const el of flaggedElements) {
    //                 el.removeAttribute("disabled")
    //                 el.classList.remove("disabled")
    //             }
    //         }
    //     }
    // }

    function updateActionBar() {
        let selectedItems = getSelection();
        elt.toggleClass('open', selectedItems.length > 0);
        selection.val(selectedItems.join('-'));
        // oneItemOnly()
    }

    function getSelection() {
        let selection = [];
        checkboxes().filter(":checked").each(function() {
            selection.push(this.value);
        });
        return selection;
    }
}
