// Copyright 2019 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');
var scrollPadding = 10; // Leave this much room at the top of the page when scrolling

$.fn.scrollTo = function() {
    var target = this.eq(0);

    // The page header sits above the normal content, so we need to take into
    // account when scrolling.  We want to scroll so that the element is just
    // below the bottom of the header
    var header = $('#page-header');
    if(header.length) {
        var headerHeight = header.height();
    } else {
        var headerHeight = 0;
    }

    $('html, body').animate({
        scrollTop: target.offset().top - headerHeight - scrollPadding,
    }, 1000, function() {
        // Focus the element after the animation
        target.focus();
    });
}
